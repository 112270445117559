import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ITrendingToken } from '@interfaces/admin/trendingTokens';
import type { Identifier, XYCoord } from 'dnd-core';

import { displayTokenPrice } from '@features/utils/numbers';

import {
  CancelIcon,
  DecreaseIcon,
  DragIcon,
  IncreaseIcon,
} from '@shared/assets';
import { toastWarning } from '@shared/utils';

import { useSelectedToken } from '../provider/context';

interface DraggableRowProps {
  index: number;
  id: string;
  item: ITrendingToken;
  moveRow: (fromIndex: number, toIndex: number) => void;
}

const ItemTypes = {
  CARD: 'card',
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const get24hPriceChangeData = (priceChange: string) => {
  if (!priceChange) return { increase: false, value: '-' };
  const data = priceChange.split('-');
  return {
    increase: data[0] === '' ? false : true,
    value: Number(data[0] === '' ? data[1] : data[0]).toFixed(2),
  };
};

const DraggableRow: FC<DraggableRowProps> = (props: DraggableRowProps) => {
  const { id, item, index, moveRow } = props;
  const { rows, setRows } = useSelectedToken();

  const { price, isShorten } = displayTokenPrice(item?.price);

  const priceChangePercentage24h = item.priceChangePercentage24h
    ? get24hPriceChangeData(item.priceChangePercentage24h)
    : null;

  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLButtonElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  drag(dragRef);

  const onCancelChange = () => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    toastWarning(
      'Token has been removed from list. Click Save to apply these changes.'
    );
  };

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={`font-small grid grid-cols-[6rem_1fr_13rem_13rem_13rem_13rem_4rem] items-center border-b border-darkSlate p-4 font-medium transition ${
        isDragging ? 'z-10 opacity-0' : 'opacity-100'
      }`}
    >
      <div className="p-2">{item.marketCapRank ?? '-'}</div>
      <div className="flex items-center gap-2 p-2">
        <img src={item.image} alt={item.name} className="h-6 w-6" />
        <span>{item.name}</span>
        <div className="bg-dustyBlue h-1 w-1 rounded-full"></div>
        <span className="uppercase">{item.symbol}</span>
      </div>
      <div className="p-2">
        <p className="font-small">
          {item?.price ? (
            <span>
              $
              {typeof isShorten === 'boolean' && !isShorten ? (
                price
              ) : (
                <span>
                  0.0
                  <sub className="font-xsmall">{isShorten?.subDecimals}</sub>
                  <span>{isShorten?.tail}</span>
                </span>
              )}
            </span>
          ) : (
            '-'
          )}
        </p>
      </div>
      <div className="flex items-center gap-2 p-2">
        {priceChangePercentage24h ? (
          <>
            {priceChangePercentage24h?.increase ? (
              <IncreaseIcon />
            ) : (
              <DecreaseIcon />
            )}
            <span
              className={
                priceChangePercentage24h?.increase
                  ? 'text-green500'
                  : 'text-hotPepper'
              }
            >
              {priceChangePercentage24h?.value}%
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
      <div className="p-2">
        {item?.volume24h
          ? `$${Number(item.volume24h ?? 0).toLocaleString('en-US')}`
          : '-'}
      </div>
      <div className="p-2">
        {item.marketCap
          ? `$${Number(item.marketCap ?? 0).toLocaleString('en-US')}`
          : '-'}
      </div>
      <div className="flex items-center gap-4 p-2">
        <button ref={dragRef}>
          <DragIcon />
        </button>
        <button onClick={onCancelChange}>
          <CancelIcon />
        </button>
      </div>
    </div>
  );
};
export default DraggableRow;
