import { createContext, useContext } from 'react';
import { SelectedTokenContextType } from './types';

const SelectedTokenContext = createContext<SelectedTokenContextType>({
  setSelectedToken: () => { },
  selectedToken: null,
  tempToken: null,
  setTempToken: () => { },
  isOpen: false,
  onOpen: () => { },
  onClose: () => { },
  rows: [],
  setRows: () => { },
  isChanged: false,
  keyword: '',
  setKeyword: () => { },
});

const useSelectedToken = () => useContext(SelectedTokenContext);

export { SelectedTokenContext, useSelectedToken };
