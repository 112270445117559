import { useEffect, useState } from 'react';

import { Socket } from 'socket.io-client';

import { createUserSocket } from './createUserSocket';


const useUserSocket = () => {
  const [userSocket, setUserSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = createUserSocket();
    newSocket.connect();

    setUserSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return { socket: userSocket };
};
export default useUserSocket;
