import { useQuery } from '@tanstack/react-query';

import { TRENDING_TOKENS } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';
import { useMemo } from 'react';
import { ITrendingToken } from '@interfaces/admin/trendingTokens';

export const useGetTrendingTokens = () => {
  const { data, ...restData } = useQuery({
    queryKey: [TRENDING_TOKENS],
    queryFn: () => marketplaceApi.client.getTrendingTokens(),
  });
  return {
    data: useMemo(() => data?.data || ([] as ITrendingToken[]), [data?.data]),
    ...restData
  };
}

