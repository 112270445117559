'use client';

import { useEffect, useMemo, useRef, useState } from 'react';

import { ITrendingToken } from '@interfaces/admin/trendingTokens';

import useReadTrendingTokensEvent from '@api/trendingTokens/read/useReadTrendingTokensEvent';
import { useGetTrendingTokens } from '@api/user/marketplace/queries/useGetTrendingTokens';

import { get24hPriceChangeData } from '@features/admin/TrendingTokens/table/DraggableRow';

import config from '@shared/config';
import { useScreenWidth } from '@shared/hooks';

export const TrendingTokens = () => {
  const { data } = useGetTrendingTokens();

  const trendingTokens = useMemo(
    () => Array(5).fill(data).flat() as ITrendingToken[],
    [data]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [translateXValue, setTranslateXValue] = useState(100);

  const isMobile = useScreenWidth();

  useReadTrendingTokensEvent();

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const contentWidth = contentRef.current?.scrollWidth || 0;

    const translateX = contentWidth + containerWidth;
    setTranslateXValue(translateX);
  }, [trendingTokens]);

  const handleClickToken = (id: string) => {
    window.open(`${config.coinGeckoUrl}/${id}`, '_blank');
  };

  if (!trendingTokens || trendingTokens.length === 0) return null;

  return (
    <div className="relative mx-auto flex max-w-[1700px] items-center gap-5 p-4 pb-10 table-screen:gap-10 lg:gap-10 lg:py-20 lg:pl-[90px]">
      <p className="font-large table-screen:font-xxlarge text-nowrap font-bold">
        Trending Tokens
      </p>
      <div
        ref={containerRef}
        className="bg-gray-800 overflow-hidden whitespace-nowrap py-2"
      >
        <div
          ref={contentRef}
          className="font-xsmall table-screen:font-small flex animate-scrollLeft items-center font-bold"
          style={{
            animation: `scrollLeft ${translateXValue / (isMobile ? 500 : 200)}s linear infinite`,
            transform: `translateX(${translateXValue}px)`,
          }}
        >
          {trendingTokens.map((token, index) => {
            const { increase, value } = get24hPriceChangeData(
              token?.priceChangePercentage24h
            );
            return (
              <div
                className="mx-5 flex w-fit cursor-pointer items-center gap-1 md:mx-6 table-screen:mx-8"
                key={`${token.id}-${index}`}
                role="button"
                tabIndex={0}
                onClick={() => handleClickToken(token.id)}
              >
                <p>{`#${token.marketCapRank ?? '-'}`}</p>
                <img
                  src={encodeURI(token?.image)}
                  alt={token?.name}
                  className="h-5 w-5 sm:h-6 sm:w-6"
                />
                <p className="uppercase">{token?.symbol ?? token?.name}</p>
                <p className={increase ? 'text-green500' : 'text-hotPepper'}>
                  {token?.priceChangePercentage24h ? `${value}%` : '-'}
                </p>

                {'   '}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
