import { useCallback, useEffect } from 'react';

import useUserSocket from '../useUserSocket';
import { useQueryClient } from '@tanstack/react-query';
import { ITrendingToken } from '@interfaces/admin/trendingTokens';
import { TRENDING_TOKENS } from '@shared/constants';

const useReadTrendingTokensEvent = () => {

  const { socket } = useUserSocket();

  const queryClient = useQueryClient();

  const compareToChange = useCallback((newData: ITrendingToken[]) => {
    const oldData = queryClient.getQueryData<ITrendingToken[]>([TRENDING_TOKENS]);
    return JSON.stringify(oldData) !== JSON.stringify(newData);
  }, [queryClient]);

  const handleUpdateTrendingTokens = useCallback(
    (data: ITrendingToken[]) => {
      const isChanged = compareToChange(data);
      if (isChanged) {
        queryClient.setQueryData([TRENDING_TOKENS], { data });
      }
    },
    [queryClient, compareToChange]
  );

  useEffect(() => {
    socket?.on('trending-tokens', handleUpdateTrendingTokens);

    return () => {
      socket?.off('trending-tokens', handleUpdateTrendingTokens);
    };
  }, [socket, handleUpdateTrendingTokens]);

};

export default useReadTrendingTokensEvent;
