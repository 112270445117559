'use client';

import Link from 'next/link';
import { useRef, useState } from 'react';

import 'swiper/css';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper as ReactSwiper, SwiperClass, SwiperSlide } from 'swiper/react';

import useGetFeaturedProductsQuery from '@api/user/marketplace/queries/useGetFeaturedProductsQuery';

import { getProductUrlSeo } from '@components/productCard/ProductCard';

import SwiperNavigation from './SwiperNavigation';
import SwiperSlideElement from './SwiperSlideElement';

const Swiper = () => {
  const { data: featuredProducts } = useGetFeaturedProductsQuery();
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef<SwiperClass>();

  return (
    <div className="mx-auto mt-10 flex w-full max-w-[920px] items-start justify-between gap-10 max-lg:flex-col lg:mt-0 lg:items-center lg:pr-10">
      <div className="swiperPolygonNotch mx-auto flex h-full max-h-[33rem] w-full p-[1.5px] lg:max-h-[33rem] lg:max-w-[800px] lg:bg-green900">
        <div className="swiperPolygonNotch h-[73vw] max-h-[32rem] min-h-96 w-full lg:h-[39vw] lg:bg-neutral1000 lg:p-5">
          <ReactSwiper
            modules={[Autoplay, Navigation, Pagination]}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="h-full"
            slidesPerView={1}
            centeredSlides
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            speed={1500}
            // loop
            breakpoints={{
              1024: {
                height: undefined,
                direction: 'vertical',
              },
              768: {
                height: 490,
                direction: 'horizontal',
              },
            }}
            navigation={{
              nextEl: '#swiper-button-next',
              prevEl: '#swiper-button-prev',
            }}
            initialSlide={0}
            onActiveIndexChange={(swiper) => {
              setActiveIndex(swiper.realIndex);
            }}
          >
            {featuredProducts?.data.map((featuredProduct) => {
              const { id, name, productTypeName } = featuredProduct.product;
              return (
                <SwiperSlide key={id}>
                  <Link href={`${getProductUrlSeo(productTypeName, id, name)}`}>
                    <SwiperSlideElement
                      productImage={featuredProduct.product.thumbnail}
                      productName={name}
                      publisher={featuredProduct.product.publisher}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </ReactSwiper>
        </div>
      </div>
      <SwiperNavigation
        activeIndex={activeIndex}
        swiperRef={swiperRef}
        data={featuredProducts?.data}
      />
    </div>
  );
};

export default Swiper;
