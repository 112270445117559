import { Socket, io } from 'socket.io-client';

import config from '@shared/config';

export const createUserSocket = (): Socket => {
  return io(`${config.userApiUrl}/public`, {
    autoConnect: false,
    transports: ['websocket'],
  });
};
